import { createStore as reduxCreateStore } from "redux";

const reducer = (state, action) => {
  if (action.type === `SET_LANGUAGE`) {
    return Object.assign({}, state, {
      language: action.language
    })
  }
  return state;
}

const initialState = { language: '' };
const createStore = () => reduxCreateStore(reducer, initialState);
export default createStore;